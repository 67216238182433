export enum DashboardChartTitles {
  CONVERSATIONS = 'Conversations',
  DIRECT_TO_SV = 'Temp',
  COMPANIES = 'Companies',
  NOTIFICATIONS = 'Notifications',
}

export enum DashboardChartTypes {
  LINE = 'line',
  DOUGHNUT = 'doughnut',
}
