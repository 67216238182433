export enum StaticStrings {
  DELETE = 'DELETE',
  SOLE = 'SOLE',
  NONE = 'None',
  CONFIRM = 'CONFIRM',
}

export enum ActiveStatus {
  ALL_ACTIVE = 'All Active',
  ALL_GPT = 'All GPT',
  ALL_LLM = 'All LLM',
  ALL_NOT_ACTIVE = 'All Not Active',
  ALL_AI_TRAINERS = 'All AI TRAINERS',
  ALL_OFFICE = 'All OFFICE',
  CUSTOM = 'Custom',
}

export enum RephrasingAIModels {
  LLM = 'LLM',
  GPT = 'GPT',
  LIBRARY = 'Library',
}
